html {
    height: 100%;
}

body {
    height: 0;
    margin: 0;
}

* {
    color: black;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    box-sizing: border-box;
}

.container {
    width: 1140px;
    margin: 0 auto;
    padding: 0; 

    @media (max-width: 1199px) {
        width: 976px;
    }

    @media (max-width: 991px) {
        width: 738px;
    }

    @media (max-width: 767px) {
        width: 546px;
    }

    @media (max-width: 576px) {
        width: 480px;
        padding: 0 16px;
    }

    @media (max-width: 480px) {
        width: 360px;
        padding: 0 16px;
    }
}

a {
    color: rgba(45, 45, 45, 1);
    text-decoration: none;
    :hover {
        text-decoration: none;
        color: rgba(45, 45, 45, 1);  
    }
}