.carousel {
    overflow: hidden;

    .slick-track {
        height: 180px;
        margin-top: 16px;
        margin-bottom: 10px;

        

        @media (max-width: 991px) { 
            height: 100px !important;
        }

    }

    &-item {
        width: 396px;
        height: 170px !important;
        border-radius: 16px;
        background-color:#fff;
        margin: 0 16px;
        box-shadow: 0px 0px 16px 0px rgba(229, 229, 229, 1);
        padding: 32px;
        position: relative;

        @media (max-width: 1313px) { 
            height: 190px !important;
        }

        @media (max-width: 1120px) { 
            height: 210px !important;
        }

        @media (max-width: 1000px) { 
            height: 150px !important;
        }

        @media (max-width: 684px) { 
            height: 174px !important;
        }

        @media (max-width: 518px) { 
            height: 190px !important;
        }

        @media (max-width: 440px) { 
            height: 210px !important;
        }

        @media (max-width: 400px) { 
            height: 240px !important;
        }

        @media (max-width: 375px) { 
            height: 256px !important;
        }

        @media (max-width: 370px) { 
            height: 278px !important;
        }
        
        .title {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }

        .descr {
            margin-top: 16px;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
        }

        &_img {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 32px;
            right: 32px;

            @media (max-width: 480px) {
                right: 12px;
            }

            img {
                width: 100%;
                object-fit: cover;
            }
        }
    }

    ul.slick-dots {
        position: relative !important;
        margin: 0 auto;
        margin-top: 32px;
        padding: 10px 0px !important;
        padding-left: 12px !important;
        width: 130px !important;
        border-radius: 30px !important;
        background: rgba(255, 255, 255, 1) !important;
        bottom: 0;


        li {
            width: 8px !important;
            height: 8px !important;
        }

        button {
            &::before {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #000;
                position: absolute;
                left: 0;
                transform: translateX(-50%);
                transition: width 0.5s;
            }
            
        }

    }
    .slick-dots li.slick-active button:before {
        width: 16px;
        height: 8px;
        border-radius: 8px;
        transition: width 0.5s;

    }
} 

.carousel_how-works {
    display: none !important;

    @media (max-width: 767px) { 
        display: block !important;
    }

    ul.slick-dots {
        border: 1px solid rgba(245, 245, 245, 1);

        
    }

    .carousel-item {
        height: 330px !important;
        box-shadow: none;

        @media (max-width: 767px) { 
            margin: 0 18px;
            padding: 20px;
            height: 310px !important;
        }

        .steps_item {
            @media (max-width: 480px) {
                height: 320px !important;
                width: 300px !important;
            }
        }

        .steps_item:last-child {
            margin-top: 0;
        }

    }
}

.carousel_clients {
    display: none !important;

    @media (max-width: 767px) { 
        display: block !important;
    }

    .slick-track {
        height: 50px;
    }
    .carousel-item {
        height: 330px !important;
        box-shadow: none;
        border: 1px solid rgba(245, 245, 245, 1);


        @media (max-width: 767px) { 
            margin: 0 18px;
            padding: 20px;
            height: 90px !important;
            display: flex !important;
            justify-content: center !important;
        }

        .steps_item:last-child {
            margin-top: 0;
        }

    }

    ul.slick-dots {
        display: none !important;

        
    }


}