@font-face {
	font-family: 'Roboto';
    font-weight: 100;
	src: url('/fonts/Roboto-Thin.ttf');
}

@font-face {
	font-family: 'Roboto';
    font-weight: 300;
	src: url('/fonts/Roboto-Light.ttf');
}

@font-face {
	font-family: 'Roboto';
    font-weight: 400;
	src: url('/fonts/Roboto-Regular.ttf');
}

@font-face {
	font-family: 'Roboto';
    font-weight: 500;
	src: url('/fonts/Roboto-Medium.ttf');
}

@font-face {
	font-family: 'Roboto';
    font-weight: 700;
	src: url('/fonts/Roboto-Bold.ttf');
}