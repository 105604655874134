.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.94);


    .modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 738px;
        border-radius: 16px;
        z-index: 100;
        box-shadow: 0px 0px 16px 0px rgba(229, 229, 229, 1);
        background: rgba(255, 255, 255, 1);
        padding: 32px;

        @media (max-width: 767px) {
            width: 92vw;
            padding: 16px;
        }

        &__title {
            color: #0D0D0D;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }

        &__descr {
            margin-top: 22px;
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;

            @media (max-width: 767px) {
                display: none;
            }
        }

        &__close {
            position: absolute;
            top: 16px;
            right: 16px;
            cursor: pointer;
            font-size: 40px;
            line-height: 25px;
            color: #acac9f;

        }
        &_thanks {
            padding: 64px 0;

            .modal__img {
                height: 48px;
                width: 48px;
                margin: 0 auto;

                img {
                    width: 100%;
                }
            }
            .modal__descr {
                margin-top: 16px;
                font-size: 20px;
                font-weight: 300;
                line-height: 48px;
                text-align: center;
            }
        }
    }
}

#order, #consultation, #thanks {
    display: none;
}

.no-scroll {
    overflow: hidden;
}

.form_modal {
    
    width: 396px;
    margin: 0 auto;
    margin-top: 32px;

    @media (max-width: 767px) {
        width: 100%;
        margin-top: 16px;
    }

    .form-group {
        display: flex;
        flex-direction: column;

        label {
            margin: 32px 0 16px 16px;
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;

            @media (max-width: 767px) {
                margin: 16px 0 8px 8px;
            }
        }

        input, textarea {
            padding: 16px;
            width: 100%;
            height: 48px;
            border-radius: 8px;
            border: 1px solid rgba(216, 216, 216, 1);
        }

        textarea {
            height: 96px;
            resize: none;
            width: 100% !important;

            @media (max-width: 767px) {
                height: 48px;
                padding: 14px;
            }
        }
    }
    .button_submit {
        width: 101px;
        height: 48px;
        border-radius: 48px;
        background: rgba(0, 0, 0, 1);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: rgba(255, 255, 255, 1);
        display: block;
        margin: 0 auto;
        margin-top: 32px;
        cursor: pointer;

        @media (max-width: 767px) {
            margin-top: 16px;
        }

        

    }
}